import React, { memo } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Container from 'react-bootstrap/Container';
import Head from 'next/head';

const CounterPage = dynamic(() => import('./Components/CounterPage'), {
  ssr: false,
});
const FaqPage = dynamic(() => import('./Components/FaqPage'), {
  ssr: false,
});
const HomeBlogPage = dynamic(() => import('./Components/HomeBlogPage'), {
  ssr: false,
});
const SliderPage = dynamic(() => import('./Components/SliderPage'), {
  ssr: false,
});
const TopPage = dynamic(() => import('./Components/TopPage'), {
  ssr: false,
});
function Index() {
  return (
    <>
      <Head>
        <link rel="preload" href="/assets/img/website-banner.webp" as="image" />
        <link rel="preload" href="/assets/img/website-banner-mob.webp" as="image" />
        <link rel="preload" href="/assets/img/free-badge.webp" as="image" />
        <link rel="preload" href="/assets/img/free-badge-mob.webp" as="image" />
      </Head>
      <CounterPage />
      <TopPage />
      <div className="why-choose">
        <Container>
          <h2>Why Choose 247torax?</h2>
          <p>
            Escort call girls from 247torax are well known for the wide range of services they offer. We come up with
            specialized and customized services per the client's needs and preferences. Your companions are well-trained
            in social etiquette and can create a comfortable and engaging atmosphere for the clients. Moreover, we focus
            on customer satisfaction and can deliver a unique and enjoyable experience that you will remember for the
            rest of your life.
          </p>
        </Container>
      </div>
      <SliderPage />
      <div className="ads-free-sec">
        <Container>
          <div className="title-cls free-ads-para">
            <h2>Indian Escort Services from 247torax</h2>
            <p>
              You might be tired of the hectic life circle and in the mood to relax and wish to have fun. Why not book
              the best services from an <Link href={'/escorts'}> Indian escort service </Link>? 247torax offers the
              ultimate sexual experience to the clients irrespective of the city and the desire you have. Your friends
              are at your service at any point in time. We have been popular for our professionalism, discretion, and
              commitment to maintaining client confidentiality. One can get a cultural and fulfilling experience to get
              companionship and escort services.
            </p>
            <Link href="/ads-post/free" className="btn_primary" role="button">
              Click Here
            </Link>
          </div>
          <div className="post-banner">
            <Link href="/ads-post/free">
              <img loading="lazy" src="/assets/img/website-banner.webp" alt="Free Ads" className="desktop-img" />
              <img loading="lazy" src="/assets/img/website-banner-mob.webp" alt="Free Ads" className="mob-img" />
            </Link>
            <div className="post-badge">
              <Link href="/ads-post/free">
                <img loading="lazy" src="/assets/img/free-badge.webp" alt="Free Ads" className="desktop-img" />
                <img loading="lazy" src="/assets/img/free-badge-mob.webp" alt="Free Ads" className="mob-img" />
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <HomeBlogPage />
      <FaqPage />
    </>
  );
}

export default memo(Index);
